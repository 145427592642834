<form [formGroup]="formGroup">
  <form-field label="Eesnimi" [required]="true">
    <app-input for="firstName"></app-input>
  </form-field>

  <form-field label="Perenimi" [required]="true">
    <app-input for="lastName"></app-input>
  </form-field>

  <form-field label="Kasutajanimi" [required]="true">
    <app-input for="username"></app-input>
  </form-field>

  <form-field label="Isikukood">
    <app-input for="idCode"></app-input>
  </form-field>

  <form-field label="E-post" [required]="true">
    <app-input for="email"></app-input>
  </form-field>

  <form-field label="Telefoni number" [required]="true">
    <div class="flex gap-2x">
      <app-select
        class="w-auto min-w-20"
        for="phoneCode"
        [options]="formData.phoneCodes"
        value="id"
        labelField="value"
        [clearable]="false"
      ></app-select>
      <app-input
        for="phoneNumberShort"
        class="flex flex-1 lg:w-50x"
      ></app-input>
    </div>
  </form-field>

  <form-field label="Seos asukohaga"> {{userLocations}} </form-field>

  <button
    class="mt-7 min-w-full"
    size="medium"
    appButton
    color="primary"
    label="Salvesta"
    icon="monochrome-save.svg"
  ></button>
</form>
