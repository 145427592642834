import { JsonPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterLink,
  RouterLinkActive,
  RouterStateSnapshot,
} from '@angular/router';
import { ViewContentComponent } from 'src/app/components/_layout/view-content/view-content.component';
import { ViewHeaderComponent } from 'src/app/components/_layout/view-header/view-header.component';
import { ButtonComponent } from 'src/app/components/form/button/button.component';
import { FormFieldComponent } from 'src/app/components/form/form-field/form-field.component';
import { InputComponent } from 'src/app/components/form/input/input.component';
import { SelectComponent } from 'src/app/components/form/select/select.component';
import { UserService } from 'src/app/services/user.service';

export const userProfileResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(UserService).getProfileInfo();
};

@Component({
  selector: 'app-user-settings-view',
  templateUrl: './user-settings.view.html',
  styleUrls: ['./user-settings.view.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    SelectComponent,
    InputComponent,
    FormFieldComponent,
    ReactiveFormsModule,
    JsonPipe,
  ],
})
export class UserSettingsView implements OnInit {
  public formGroup: FormGroup;

  public locationOptions = [
    {
      name: 'Harkujärve tankla',
    },
    {
      name: 'Tartu tankla',
    },
    {
      name: 'Tallinna tankla',
    },
  ];

  public formData: any;

  public userLocations = [];

  constructor(private _route: ActivatedRoute) {
    const { data } = _route.snapshot.data || {};
    const { formData, mailNotificationSettings, smtpSettings, userInfo } =
      data || {};

    this.formData = formData;

    const {
      firstName,
      lastName,
      email,
      phoneCode,
      phoneNumberShort,
      userName,
      idCode,
      locations,
    } = userInfo || {};

    this.formGroup = new FormGroup({
      firstName: new FormControl(firstName),
      lastName: new FormControl(lastName),
      username: new FormControl(userName),
      email: new FormControl(email),
      phoneCode: new FormControl(phoneCode?.id),
      phoneNumberShort: new FormControl(phoneNumberShort),
      idCode: new FormControl(idCode),
    });

    this.userLocations = locations?.length > 0 ? locations.join(', ') : '-';
  }

  ngOnInit() {}
}
