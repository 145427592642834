import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { IUserMailNotificationSettings, IUserMailSettingsData } from '../models';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private _http: HttpClient) {}

  public dashboard(data: any = {}) {
    return this._http.get<IWelcomeCardInfo>(`api/user/dashboard`);
  }

  public getProfileInfo() {
    return this._http.get<IUserProfileInfo>(`api/user/profileInfo`);
  }

  public saveProfileInfo(data: any) {
    return this._http.post<any>(`api/user/profileInfo`, data);
  }

  public updatePassword(data: any) {
    return this._http.post<any>(`api/user/updatePassword`, data);
  }

  public requestResetPasswordLink(
    email: string,
    language: string,
  ): Observable<any> {
    return this._http.post<any>(`api/user/requestResetPasswordLink`, {
      email: email,
      uiLanguage: language,
    }) as Observable<any>;
  }

  public resetPassword(data: IResetPasswordRequest) {
    return this._http.post<any>(`api/user/resetPassword`, data);
  }

  public save(id: string, data: IUserInfo) {
    return this._http.post(`api/user/${id || ''}`, data);
  }

  public activate(data: any) {
    return this._http.post(`api/user/activate`, data);
  }

  public invite(data: any) {
    return this._http.post('api/user/invite', data);
  }

  public getInviteLink(userId: string) {
    return this._http.post('api/user/getInviteLink', { userId });
  }

  public cancelInvite(userId: string) {
    return this._http.post('api/user/cancelInvite', { userId });
  }

  public updateInvite(userId: string, data: any) {
    return this._http.post(`api/user/invite/${userId}`, data);
  }

  public delete(id: string) {
    return this._http.delete(`api/user/${id}`);
  }

  public setPreferredLanguage(languageId: number) {
    return this._http.post<any>(`api/user/setPreferredLanguage`, {
      language: languageId,
    });
  }

  public getUserDetailFormData(id?: string) {
    var url = 'api/user/formData';
    if (id) {
      url += `/${id}`;
    }

    return this._http.get<any>(url);
  }

  public getUserDetail(id: string) {
    return this._http.get<any>(`api/user/${id}`);
  }

  public updateUserMailNotificationSettings(data: any) {
    //IUserMailNotificationSettings
    return this._http.post(`api/user/updateMailNotificationSettings`, data);
  }

  public updateUserSmtpSettings(data: any) {
    //IUserMailSettingsData
    return this._http.post(`api/user/smtpSettings`, data);
  }

  public testSmtpSettings(data: any) {
    //IUserMailSettingsData
    return this._http.post(`api/user/testSmtpSettings`, data);
  }

  public getUnreadPurchaseOrdersCount() {
    return this._http.get(`api/user/inbox/unreadPurchaseOrdersCount`);
  }

  public getUnreadSalesOrdersCount() {
    return this._http.get(`api/user/inbox/unreadSalesOrdersCount`);
  }

  public syncAzureUsers() {
    return this._http.post(`api/user/syncAzureUsers`, {});
  }
}

interface IWelcomeCardInfo {
  logo: string;
  companyName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  roles: string[];
}

interface IUserProfileInfo {
  firstName: string;
}

interface IUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  idCode: string;
  jobTitle: string;
  roleIds: string[];
  locationIds: string[];
}

interface IResetPasswordRequest {
  userId: string;
  token: string;
  password: string;
  passwordConfirm: string;
}
